import { About, Skills, Projects, Passions, Contact } from 'partials';

const Main = () => {
    return (
        <main className="mt-16 md:container mx-auto">
            <div className="w-4/5 mx-auto">
                <About />
                <Skills />
                <Projects />
                <Passions />
                <Contact />
            </div>
        </main>
    )
}

export default Main;