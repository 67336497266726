import { BsArrowRight } from 'react-icons/bs';
import DockerizedAndroid from 'assets/dockerized-android.png';
import Raudi from 'assets/raudi.png';
import Whale from 'assets/whale.png';
import InvisibleBackdoorDetector from 'assets/invisible-backdoor-detector.png';


const Projects = () => {
    return (
        <div id="projects" className="animate__animated animate__fadeIn">
            <h3 className="text-center">Projects</h3>

            <div className="block rounded-lg shadow-lg bg-white w-full mb-8">

                {/* Dockerized Android */}
                <div className="flex p-8 flex gap-8 md:flex-row flex-col">
                    <div className="flex md:basis-4/12 items-center justify-center">
                        <img src={DockerizedAndroid} className="w-full" alt="Dockerized Android"/>
                    </div>

                    <div className="basis-8/12">
                        <h4 className="text-center">
                            Dockerized Android
                        </h4>
                        <p className="text-justify">
                            <b>Dockerized Android</b> is a container-based framework that allows to execute and <b>Android Emulator</b> inside Docker and control it through a browser. This project has been developed in order to provide a starting point for integrating mobile security components into Cyber Ranges but it can be used for any purpose.
                        </p>
                        <a href="https://github.com/cybersecsi/dockerized-android">
                            <button type="button" className="mt-8 inline-block px-6 py-2.5 bg-gray-900 text-white font-medium text-sm leading-tight uppercase rounded shadow-md hover:bg-gray-600 hover:shadow-lg transition duration-150 ease-in-out">
                                Source Code <BsArrowRight className="inline ml-2" />
                            </button>
                        </a>
                    </div>
                </div>

                <hr className="my-6 w-5/6 mx-auto"/>

                {/* RAUDI */}
                <div className="flex p-8 flex gap-8 md:flex-row flex-col">
                    <div className="flex md:basis-4/12 items-center justify-center">
                        <img src={Raudi} className="w-3/6" alt="RAUDI"/>
                    </div>

                    <div className="basis-8/12">
                        <h4 className="text-center">
                            RAUDI
                        </h4>
                        <p className="text-justify">
                            RAUDI (Regularly and Automatically Updated Docker Images) automatically generates and keep updated a series of Docker Images through GitHub Actions for tools that are not provided by the developers.
                        </p>
                        <a href="https://github.com/cybersecsi/RAUDI">
                            <button type="button" className="mt-8 inline-block px-6 py-2.5 bg-gray-900 text-white font-medium text-sm leading-tight uppercase rounded shadow-md hover:bg-gray-600 hover:shadow-lg transition duration-150 ease-in-out">
                                Source Code <BsArrowRight className="inline ml-2" />
                            </button>
                        </a>
                    </div>
                </div>


                <hr className="my-6 w-5/6 mx-auto"/>

                {/* HOUDINI */}
                <div className="flex p-8 flex gap-8 md:flex-row flex-col">
                    <div className="flex md:basis-4/12 items-center justify-center">
                        <img src={Whale} className="w-3/6" alt="HOUDINI"/>
                    </div>

                    <div className="basis-8/12">
                        <h4 className="text-center">
                            HOUDINI
                        </h4>
                        <p className="text-justify">
                        HOUDINI (Hundreds of Offensive and Useful Docker Images for Network Intrusion) is a curated list of Network Security related Docker Images for Network Intrusion purposes. A lot of images are created and kept updated through our RAUDI project which is able to automatically update a Docker Image every time there is a new version.
                        </p>
                        <a href="https://github.com/cybersecsi/HOUDINI">
                            <button type="button" className="mt-8 inline-block px-6 py-2.5 bg-gray-900 text-white font-medium text-sm leading-tight uppercase rounded shadow-md hover:bg-gray-600 hover:shadow-lg transition duration-150 ease-in-out">
                                Source Code <BsArrowRight className="inline ml-2" />
                            </button>
                        </a>
                    </div>
                </div>


                <hr className="my-6 w-5/6 mx-auto"/>

                {/* Invisible Backdoor Detector */}
                <div className="flex p-8 flex gap-8 md:flex-row flex-col">
                    <div className="flex md:basis-4/12 items-center justify-center">
                        <img src={InvisibleBackdoorDetector} className="w-full" alt="Invisible Backdoor Detecto"/>
                    </div>

                    <div className="basis-8/12">
                        <h4 className="text-center">
                            Invisible Backdoor Detector
                        </h4>
                        <p className="text-justify">
                            Invisible Backdoor Detector is a little Python script that allows you to spot and remove Bidi characters that could lead to an invisible backdoor.
                        </p>
                        <a href="https://github.com/cybersecsi/invisible-backdoor-detector">
                            <button type="button" className="mt-8 inline-block px-6 py-2.5 bg-gray-900 text-white font-medium text-sm leading-tight uppercase rounded shadow-md hover:bg-gray-600 hover:shadow-lg transition duration-150 ease-in-out">
                                Source Code <BsArrowRight className="inline ml-2" />
                            </button>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Projects;