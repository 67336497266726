//import { MdDarkMode, MdLightMode } from 'react-icons/md';
import { ReactComponent as Logo } from 'assets/logo.svg';

const Header = () => {
    return (
        <>
            <header className="md:container w-4/5 mx-auto py-6 flex max-h-32">
                {/* Logo */}
                <div className="w-4/6 md:w-full mx-auto max-h-full">
                    <Logo id="logo" className="max-h-full fill-white mx-auto pt-4" />
                </div>
            </header>
            {/* Absolute Header BG */}
            <div id="header-bg" className="absolute bg-cover bg-no-repeat bg-center w-screen h-96 top-0"/>
        </>
    )
}

export default Header;