import { BsArrowRight } from 'react-icons/bs';

const Contact = () => {
    return (
        <div id="contact" className="animate__animated animate__fadeIn">
            <div className="text-center mt-8">
                <h3>Interested in working together?</h3>
                <a href="mailto:delicato.angelo@gmail.com">
                    <button type="button" className="mt-8 inline-block px-6 py-2.5 bg-gray-900 text-white font-medium text-large leading-tight uppercase rounded shadow-md hover:bg-gray-600 hover:shadow-lg transition duration-150 ease-in-out">
                        Get in touch <BsArrowRight className="inline ml-2" />
                    </button>
                </a>
            </div>
        </div>
    )
}

export default Contact;