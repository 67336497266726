import { Header, Main, Footer } from 'containers';

const App = () => {
  return (
    <>
      <Header/>
      <Main/>
      <Footer/>
    </>
  );
}

export default App;